// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/:lang?`
  | `/:lang?/admin`
  | `/:lang?/login`
  | `/:lang?/post`
  | `/:lang?/pre`
  | `/:lang?/reset-password`
  | `/:lang?/reset-password-verify`
  | `/:lang?/resources`
  | `/:lang?/resources/assigned-slides`
  | `/:lang?/resources/background/introduction`
  | `/:lang?/resources/background/moa`
  | `/:lang?/resources/background/practical-use`
  | `/:lang?/resources/burden`
  | `/:lang?/resources/faq/icodec-and-hospitalisation`
  | `/:lang?/resources/faq/icodec-and-hypoglycaemia`
  | `/:lang?/resources/icodec-and-diabetes/insulin-naive`
  | `/:lang?/resources/icodec-and-diabetes/prior-insulin`
  | `/:lang?/resources/icodec-faq`
  | `/:lang?/resources/insulin-naive-t2d/lancet-onwards-1`
  | `/:lang?/resources/insulin-naive-t2d/onwards-1`
  | `/:lang?/resources/insulin-naive-t2d/onwards-1-supplement`
  | `/:lang?/resources/insulin-naive-t2d/onwards-3`
  | `/:lang?/resources/insulin-naive-t2d/onwards-3-supplement`
  | `/:lang?/resources/insulin-naive-t2d/onwards-5`
  | `/:lang?/resources/insulin-naive-t2d/onwards-5-supplement`
  | `/:lang?/resources/insulin-treated-t2d-t1d/lingvay-2024-pk-pd-modeling`
  | `/:lang?/resources/insulin-treated-t2d-t1d/onwards-2`
  | `/:lang?/resources/insulin-treated-t2d-t1d/onwards-2-and-4-post-hoc`
  | `/:lang?/resources/insulin-treated-t2d-t1d/onwards-2-supplement`
  | `/:lang?/resources/insulin-treated-t2d-t1d/onwards-4`
  | `/:lang?/resources/insulin-treated-t2d-t1d/onwards-4-supplement`
  | `/:lang?/resources/insulin-treated-t2d-t1d/onwards-6`
  | `/:lang?/resources/insulin-treated-t2d-t1d/onwards-6-supplement`
  | `/:lang?/resources/onwards`
  | `/:lang?/resources/other-publications/bajaj-2024-icodec-vs-daily-ow1`
  | `/:lang?/resources/other-publications/bajaj-2024-icodec-vs-daily-ow1-supplement`
  | `/:lang?/resources/other-publications/barmanray-2024-icodec-use-in-hospital-settings`
  | `/:lang?/resources/other-publications/clinical-perspectives`
  | `/:lang?/resources/other-publications/clinical-perspectives-supplement`
  | `/:lang?/resources/other-publications/houlden-2025-peri-operative-weekly-insulin-management`
  | `/:lang?/resources/other-publications/polonsky-2024-patient-reported-outcomes`
  | `/:lang?/resources/other-publications/rosenstock-endocrine-society-review-weekly-insulins`
  | `/:lang?/resources/overview`
  | `/:lang?/resources/product-monograph`
  | `/:lang?/resources/special-populations`
  | `/:lang?/resources/t2d-management`

export type Params = {
  '/:lang?': { lang?: string }
  '/:lang?/admin': { lang?: string }
  '/:lang?/login': { lang?: string }
  '/:lang?/post': { lang?: string }
  '/:lang?/pre': { lang?: string }
  '/:lang?/reset-password': { lang?: string }
  '/:lang?/reset-password-verify': { lang?: string }
  '/:lang?/resources': { lang?: string }
  '/:lang?/resources/assigned-slides': { lang?: string }
  '/:lang?/resources/background/introduction': { lang?: string }
  '/:lang?/resources/background/moa': { lang?: string }
  '/:lang?/resources/background/practical-use': { lang?: string }
  '/:lang?/resources/burden': { lang?: string }
  '/:lang?/resources/faq/icodec-and-hospitalisation': { lang?: string }
  '/:lang?/resources/faq/icodec-and-hypoglycaemia': { lang?: string }
  '/:lang?/resources/icodec-and-diabetes/insulin-naive': { lang?: string }
  '/:lang?/resources/icodec-and-diabetes/prior-insulin': { lang?: string }
  '/:lang?/resources/icodec-faq': { lang?: string }
  '/:lang?/resources/insulin-naive-t2d/lancet-onwards-1': { lang?: string }
  '/:lang?/resources/insulin-naive-t2d/onwards-1': { lang?: string }
  '/:lang?/resources/insulin-naive-t2d/onwards-1-supplement': { lang?: string }
  '/:lang?/resources/insulin-naive-t2d/onwards-3': { lang?: string }
  '/:lang?/resources/insulin-naive-t2d/onwards-3-supplement': { lang?: string }
  '/:lang?/resources/insulin-naive-t2d/onwards-5': { lang?: string }
  '/:lang?/resources/insulin-naive-t2d/onwards-5-supplement': { lang?: string }
  '/:lang?/resources/insulin-treated-t2d-t1d/lingvay-2024-pk-pd-modeling': { lang?: string }
  '/:lang?/resources/insulin-treated-t2d-t1d/onwards-2': { lang?: string }
  '/:lang?/resources/insulin-treated-t2d-t1d/onwards-2-and-4-post-hoc': { lang?: string }
  '/:lang?/resources/insulin-treated-t2d-t1d/onwards-2-supplement': { lang?: string }
  '/:lang?/resources/insulin-treated-t2d-t1d/onwards-4': { lang?: string }
  '/:lang?/resources/insulin-treated-t2d-t1d/onwards-4-supplement': { lang?: string }
  '/:lang?/resources/insulin-treated-t2d-t1d/onwards-6': { lang?: string }
  '/:lang?/resources/insulin-treated-t2d-t1d/onwards-6-supplement': { lang?: string }
  '/:lang?/resources/onwards': { lang?: string }
  '/:lang?/resources/other-publications/bajaj-2024-icodec-vs-daily-ow1': { lang?: string }
  '/:lang?/resources/other-publications/bajaj-2024-icodec-vs-daily-ow1-supplement': { lang?: string }
  '/:lang?/resources/other-publications/barmanray-2024-icodec-use-in-hospital-settings': { lang?: string }
  '/:lang?/resources/other-publications/clinical-perspectives': { lang?: string }
  '/:lang?/resources/other-publications/clinical-perspectives-supplement': { lang?: string }
  '/:lang?/resources/other-publications/houlden-2025-peri-operative-weekly-insulin-management': { lang?: string }
  '/:lang?/resources/other-publications/polonsky-2024-patient-reported-outcomes': { lang?: string }
  '/:lang?/resources/other-publications/rosenstock-endocrine-society-review-weekly-insulins': { lang?: string }
  '/:lang?/resources/overview': { lang?: string }
  '/:lang?/resources/product-monograph': { lang?: string }
  '/:lang?/resources/special-populations': { lang?: string }
  '/:lang?/resources/t2d-management': { lang?: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
